var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-3" },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "3" } }, [
            _c("h4", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.name))]),
            _c("p", [_vm._v("(" + _vm._s(_vm.id) + ")")])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "9" } },
            [
              !_vm.hasWriteAccess
                ? _c("div", [
                    _vm.imageUrl
                      ? _c("img", {
                          attrs: { src: _vm.imageUrl, alt: "uploaded image" }
                        })
                      : _c("p", [_vm._v("No image uploaded")])
                  ])
                : _c("image-upload", {
                    attrs: {
                      "image-object": _vm.image,
                      allowImageEdit: true,
                      allowImageFocalpointEdit: true,
                      "accepted-file-types":
                        "image/png,image/jpeg,image/gif,image/svg+xml,video/mp4"
                    },
                    on: {
                      "remove-image-object": _vm.removeImageObject,
                      "upload-image-object": _vm.updateUploadImageObject
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }