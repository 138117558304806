<template>
  <div class="mb-3">
    <b-row>
      <b-col cols="3">
        <h4 class="mb-0">{{ name }}</h4>
        <p>({{ id }})</p>
      </b-col>
      <b-col cols="9">
        <div
          v-if="!hasWriteAccess"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="uploaded image"/>
          <p v-else>No image uploaded</p>
        </div>
        <image-upload
          v-else
          :image-object="image"
          :allowImageEdit="true"
          :allowImageFocalpointEdit="true"
          accepted-file-types="image/png,image/jpeg,image/gif,image/svg+xml,video/mp4"
          @remove-image-object="removeImageObject"
          @upload-image-object="updateUploadImageObject"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'MediaField',
  components: {
    ImageUpload: () => import('@/components/helper/ImageUploadNoGroupContext.vue'),
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.image = this.value;
    console.log('this.image', this.image);
  },
  data: () => ({
    image: null,
    showDeleteModal: false,
  }),
  computed: {
    imageUrl() {
      return this.image ? this.image.url : null;
    },
  },
  methods: {
    updateValue() {
      this.$emit('update', this.image);
    },
    updateUploadImageObject(image) {
      this.image = image;
      this.updateValue();
    },
    removeImageObject() {
      this.image = null;
      this.updateValue();
    },
  },
};
</script>

<style scoped lang="scss">
.personalization-settings #content input {
  color: #495057;
  border: 1px solid #ced4da;
  background-color: #fff;
}

h4, p {
  font-size: 15px;
}

h4 {
  font-weight: 600;
}
</style>
